import { SvgIcon } from '@mui/material';

export const MailKawaii = (props) => {
  return (
    <SvgIcon viewBox="0 0 158 196" fill="none" {...props}>
      <path
        d="M150.238 126.84H7.217c-3.043 0-5.217-2.609-5.217-5.219V55.939c0-20.444 16.52-36.973 36.95-36.973h79.553c20.432 0 36.951 16.529 36.951 36.973v65.682c.435 3.045-2.173 5.219-5.216 5.219Z"
        fill="#3E3FFB"
      />
      <path
        d="M118.938 18.528H38.951C18.519 18.528 2 35.493 2 55.937V72.03c0-20.444 16.52-36.974 36.95-36.974h79.553c20.432 0 36.951 16.53 36.951 36.974V55.936c.435-20.444-16.084-37.408-36.516-37.408Z"
        fill="#5A5BFC"
      />
      <path
        d="M150.238 126.84H7.217c-3.043 0-5.217-2.609-5.217-5.219V55.939c0-20.444 16.52-36.973 36.95-36.973h79.553c20.432 0 36.951 16.529 36.951 36.973v65.682c.435 3.045-2.173 5.219-5.216 5.219Z"
        stroke="#45413C"
        strokeWidth={4}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        opacity={0.15}
        d="M86.335 196c34.572 0 62.598-3.7 62.598-8.265 0-4.564-28.026-8.264-62.598-8.264-34.573 0-62.6 3.7-62.6 8.264 0 4.565 28.027 8.265 62.6 8.265Z"
        fill="#757575"
      />
      <path
        d="M38.95 18.528C18.52 18.528 2 35.493 2 55.937v65.681c0 3.045 2.608 5.22 5.217 5.22h63.468c3.043 0 5.216-2.61 5.216-5.22V55.937c.435-20.444-16.519-37.409-36.95-37.409Z"
        fill="#E6E6FE"
      />
      <path
        d="M38.95 18.528c-9.563 0-17.823 3.48-24.343 9.135 1.739-.435 3.912-.435 6.086-.435 20.431 0 36.95 16.53 36.95 36.973v62.202h13.042c3.043 0 5.216-2.61 5.216-5.22V55.937c.435-20.444-16.084-37.409-36.95-37.409Z"
        fill="#B4B4FD"
      />
      <path
        d="M38.95 19.182C18.52 19.182 2 36.078 2 56.44v65.419c0 3.032 2.608 5.198 5.217 5.198h63.468c3.043 0 5.216-2.599 5.216-5.198v-65.42c.435-20.361-16.519-37.257-36.95-37.257Z"
        stroke="#45413C"
        strokeWidth={4}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M110.679 2H96.333c-3.043 0-5.216 2.61-5.216 5.22V75.51c0 4.35 3.477 8.265 8.259 8.265 4.347 0 8.26-3.48 8.26-8.264V33.317h3.043c3.043 0 5.216-2.61 5.216-5.22V7.22c.435-3.045-2.173-5.22-5.216-5.22Z"
        fill="#FE2F77"
        stroke="#45413C"
        strokeWidth={4}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.42 187.302H77.64c-3.043 0-5.216-2.61-5.216-5.22V126.84h25.648v54.807c0 3.045-2.609 5.655-5.652 5.655Z"
        fill="#F1CB9C"
      />
      <path d="M98.072 126.84H72.424v13.484h25.648V126.84Z" fill="#EBB470" />
      <path
        d="M92.42 187.302H77.64c-3.043 0-5.216-2.61-5.216-5.22V126.84h25.648v54.807c0 3.045-2.609 5.655-5.652 5.655Z"
        stroke="#45413C"
        strokeWidth={4}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M34.071 104.143h10.286v9A3.857 3.857 0 0 1 40.5 117h-2.571a3.857 3.857 0 0 1-3.858-3.857v-9Z"
        fill="#EBB470"
      />
      <path
        d="M36.643 104.143h10.285v9A3.857 3.857 0 0 1 43.072 117H40.5a3.857 3.857 0 0 1-3.857-3.857v-9Z"
        fill="#F1CB9C"
      />
      <path
        d="M18 57.857C18 50.757 23.756 45 30.857 45h15.429c7.1 0 12.857 5.756 12.857 12.857v33.429c0 7.1-5.757 12.857-12.857 12.857H30.857c-7.1 0-12.857-5.757-12.857-12.857V57.857Z"
        fill="#FE2F77"
      />
      <path
        d="M21.857 57.857c0-7.1 5.756-12.857 12.857-12.857h15.429C57.243 45 63 50.756 63 57.857v33.429c0 7.1-5.756 12.857-12.857 12.857H34.714c-7.1 0-12.857-5.757-12.857-12.857V57.857Z"
        fill="#FE94B9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.592 81.546c.206-.316.693-.438 1.088-.272l5.28 2.211c.72.302.72 1.128 0 1.43l-5.28 2.211c-.395.165-.882.043-1.088-.272-.206-.316-.054-.706.34-.871L31.19 84.2l-4.256-1.783c-.395-.165-.547-.555-.341-.87Z"
        fill="#50302D"
      />
      <circle cx={55.286} cy={84.214} r={3.857} fill="#50302D" />
      <circle cx={56.572} cy={82.928} r={1.286} fill="#fff" />
      <path
        d="M42.26 96.423c-1.92-.052-3.005-.412-4.583-1.39-.931-.578-1.773-1.596-2.458-2.63-1.006-1.523.203-3.367 2.028-3.367h10.626c1.752 0 2.964 1.716 2.08 3.229-.632 1.083-1.449 2.168-2.415 2.767-1.711 1.063-3.193 1.448-5.277 1.391Z"
        fill="#50302D"
      />
      <path
        d="M39.36 94.945c.858.337 1.722.485 2.9.517 1.227.033 2.245-.087 3.224-.407a3.081 3.081 0 1 0-6.123-.11Z"
        fill="#FE2F77"
      />
    </SvgIcon>
  )
}
