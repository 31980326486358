
import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

export function ExploreSelected({ color='#000', ...props }) {
  return (
    <SvgIcon {...props} fill="none" viewBox="0 0 17 21">
      <path
        d="M13.777 2.914a7.732 7.732 0 0 0-13.24 7.645.77.77 0 0 0 1.101.384L13.56 4.06a.77.77 0 0 0 .217-1.146Zm1.479 3.043a.77.77 0 0 0-1.134-.49L2.575 12.134a.77.77 0 0 0-.143 1.227 7.749 7.749 0 0 0 6.792 1.957l2.464 4.267a2.497 2.497 0 0 0 3.412.909 2.496 2.496 0 0 0 .918-3.41l-2.463-4.266a7.748 7.748 0 0 0 1.701-6.86Z"
        fill={color}
      />
    </SvgIcon>
  );
}
ExploreSelected.propTypes = {
  color: PropTypes.string,
};
