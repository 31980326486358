import { SVGProps } from "react"

export const RatingSelected2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    {...props}
  >
    <path d="M36 18a18 18 0 1 1-36 0 18 18 0 0 1 36 0Z" fill="#FFCC4D" />
    <path
      d="M11.5 18c1.38 0 2.5-1.57 2.5-3.5S12.88 11 11.5 11 9 12.57 9 14.5s1.12 3.5 2.5 3.5Zm13 0c1.38 0 2.5-1.57 2.5-3.5S25.88 11 24.5 11 22 12.57 22 14.5s1.12 3.5 2.5 3.5ZM8.67 27.87a.5.5 0 0 0 .63.03c.04-.03 3.92-2.9 8.7-2.9 4.77 0 8.66 2.87 8.7 2.9.2.14.46.13.64-.03a.5.5 0 0 0 .09-.63C27.3 27.03 24.2 22 18 22c-6.21 0-9.3 5.03-9.43 5.24a.5.5 0 0 0 .1.63Z"
      fill="#664500"
    />
  </svg>
)

export default RatingSelected2;
