import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

export const AddCircleFilled = ({ sx={}, ...props }) => (
  <SvgIcon fill="none" viewBox="0 0 18 18" {...props} sx={{ fill: 'currentColor', ...sx}}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9A9 9 0 1 1 0 9a9 9 0 0 1 18 0ZM9 4.65a.75.75 0 0 1 .75.75v2.85h2.85a.75.75 0 0 1 0 1.5H9.75v2.85a.75.75 0 0 1-1.5 0V9.75H5.4a.75.75 0 0 1 0-1.5h2.85V5.4A.75.75 0 0 1 9 4.65Z"
    />
  </SvgIcon>
);

AddCircleFilled.propTypes = {
  sx: PropTypes.object,
};
