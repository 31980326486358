import { SvgIcon } from '@mui/material';

export const SleepyKawaii = (props) => {
  return (
    <SvgIcon viewBox="0 0 114 183" fill="none" {...props}>
      <path
        d="M40.714 150.428h26.057v22.8c0 5.397-4.374 9.772-9.771 9.772h-6.514c-5.397 0-9.772-4.375-9.772-9.772v-22.8Z"
        fill="#EBB470"
      />
      <path
        d="M47.228 150.428h26.058v22.8c0 5.397-4.375 9.772-9.772 9.772H57c-5.397 0-9.772-4.375-9.772-9.772v-22.8Z"
        fill="#F1CB9C"
      />
      <path
        d="M0 33.172C0 15.182 14.583.6 32.571.6h39.086c17.989 0 32.572 14.583 32.572 32.572v84.685c0 17.989-14.583 32.572-32.572 32.572H32.571C14.583 150.429 0 135.846 0 117.857V33.172Z"
        fill="#FE2F77"
      />
      <path
        d="M9.771 33.172C9.771 15.182 24.354.6 42.343.6h39.086C99.417.6 114 15.183 114 33.172v84.685c0 17.989-14.583 32.572-32.571 32.572H42.343c-17.989 0-32.572-14.583-32.572-32.572V33.172Z"
        fill="#FE94B9"
      />
      <path
        d="M70 121.5c0 5.247-3.806 9.5-8.5 9.5s-8.5-4.253-8.5-9.5 3.806-9.5 8.5-9.5 8.5 4.253 8.5 9.5Z"
        fill="#50302D"
      />
      <path
        d="M23 96.75s-1.411 12 7.794 12c9.206 0 7.795-12 7.795-12M86.078 96.75s-1.412 12 7.794 12 7.794-12 7.794-12"
        stroke="#50302D"
        strokeWidth={3}
        strokeLinecap="round"
        fill="none"
      />
      <path
        d="M66.22 105.812h3.876V107H64.6v-1.068l3.864-6.084H64.6V98.66h5.496v1.068l-3.876 6.084ZM72.7 82.02h6.46V84H70v-1.78l6.44-10.14H70V70.1h9.16v1.78L72.7 82.02ZM60.96 92.416h5.168V94H58.8v-1.424l5.152-8.112H58.8V82.88h7.328v1.424l-5.168 8.112Z"
        fill="#000"
        fillOpacity={0.54}
      />
    </SvgIcon>
  )
}
