import { CSSProperties, FC } from 'react';


export type HomeProps = {
  color?: string,
  style?: CSSProperties,
};


export const Home: FC<HomeProps> = ({ color='rgba(0, 0, 0, 0.87)', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 22"
    {...props}
    // eslint-disable-next-line react/prop-types
    style={{ stroke: color, strokeOpacity: 0.87, ...props.style }}
  >
    <defs/>
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M1.9 6.6l6.75-5.15a2.24 2.24 0 012.7 0L18.1 6.6c.56.43.9 1.1.9 1.83V18.7c0 1.15 0 1.72-.29 2-.28.29-.84.29-1.96.29h-5.26l.01-.17V18.5h1a1 1 0 001-1v-6.14c0-.76-.37-1.49-1.03-2.02A3.94 3.94 0 0010 8.5c-.93 0-1.82.3-2.47.84a2.62 2.62 0 00-1.03 2.02v6.14a1 1 0 001 1h1V21H3.26c-1.12 0-1.68 0-1.96-.28-.29-.29-.29-.86-.29-2V8.41c0-.71.34-1.39.9-1.82z"/>
  </svg>
);
