import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

export const CheckCircleFilled = ({ sx={}, ...props }) => (
  <SvgIcon fill="none" viewBox="0 0 18 18" {...props} sx={{ fill: 'currentColor', ...sx}}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0a9 9 0 1 0 0 18A9 9 0 0 0 9 0Zm3.9 7.48a.82.82 0 1 0-1.26-1.05l-3.51 4.22L6.3 8.83A.82.82 0 0 0 5.15 10l2.45 2.45a.82.82 0 0 0 1.21-.05l4.1-4.91Z"
    />
  </SvgIcon>
);

CheckCircleFilled.propTypes = {
  sx: PropTypes.object
};
