import { SvgIcon } from '@mui/material';

export function Notebook(props) {
  return (
    <SvgIcon {...props} fill="none" viewBox="0 0 61 74">
      <defs/>
      <path fill="#FE2F77" d="M7.97 5.67H1.18C.53 5.67 0 6.2 0 6.85c0 .65.53 1.18 1.18 1.18h6.8c.64 0 1.17-.47 1.17-1.18 0-.65-.53-1.18-1.18-1.18zM7.97 13.23H1.18c-.65 0-1.18.53-1.18 1.18 0 .65.53 1.18 1.18 1.18h6.8c.64 0 1.17-.53 1.17-1.18 0-.65-.53-1.18-1.18-1.18zM7.97 20.73H1.18c-.65 0-1.18.53-1.18 1.18 0 .65.53 1.18 1.18 1.18h6.8c.64 0 1.17-.53 1.17-1.18 0-.65-.53-1.18-1.18-1.18zM7.97 28.23H1.18c-.65 0-1.18.53-1.18 1.18 0 .65.53 1.18 1.18 1.18h6.8c.64 0 1.17-.53 1.17-1.18 0-.65-.53-1.18-1.18-1.18zM7.97 35.79H1.18c-.65 0-1.18.53-1.18 1.18 0 .65.53 1.18 1.18 1.18h6.8c.64 0 1.17-.53 1.17-1.18 0-.65-.53-1.18-1.18-1.18zM7.97 43.29H1.18c-.65 0-1.18.53-1.18 1.18 0 .65.53 1.18 1.18 1.18h6.8c.64 0 1.17-.53 1.17-1.18 0-.65-.53-1.18-1.18-1.18zM7.97 50.79H1.18c-.65 0-1.18.53-1.18 1.18 0 .65.53 1.18 1.18 1.18h6.8c.64 0 1.17-.53 1.17-1.18 0-.65-.53-1.18-1.18-1.18zM7.97 58.35H1.18c-.65 0-1.18.53-1.18 1.18 0 .65.53 1.18 1.18 1.18h6.8c.64 0 1.17-.53 1.17-1.18 0-.65-.53-1.18-1.18-1.18zM7.97 65.85H1.18c-.65 0-1.18.53-1.18 1.18 0 .65.53 1.18 1.18 1.18h6.8c.64 0 1.17-.53 1.17-1.18 0-.65-.53-1.18-1.18-1.18zM33.37 30.12c-.06-.24-.06-.35-.12-.53l-.18.59-.77 2.36-.88 2.66c-.3.88-.6 1.71-.89 2.6L30 39.33h6.26l-2.9-9.21z"/>
      <path fill="#FE2F77" d="M55.04 0H8.21A5.2 5.2 0 003 5.2v63.6a5.2 5.2 0 005.2 5.2h46.83a5.2 5.2 0 005.2-5.2V5.2a5.2 5.2 0 00-5.2-5.2zM7.97 70.04a2.9 2.9 0 11.01-5.8 2.9 2.9 0 010 5.8zm0-7.56a2.9 2.9 0 11.01-5.8 2.9 2.9 0 010 5.8zm0-7.5a2.9 2.9 0 11.01-5.8 2.9 2.9 0 010 5.8zm0-7.5a2.9 2.9 0 11.01-5.8 2.9 2.9 0 010 5.8zm0-7.56a2.9 2.9 0 11.01-5.8 2.9 2.9 0 010 5.8zm0-7.5a2.9 2.9 0 11.01-5.8 2.9 2.9 0 010 5.8zm0-7.5a2.9 2.9 0 11.01-5.8 2.9 2.9 0 010 5.8zm0-7.56a2.9 2.9 0 11.01-5.8 2.9 2.9 0 010 5.8zm0-7.5a2.9 2.9 0 11.01-5.8 2.9 2.9 0 010 5.8z"/>
      <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M33.37 54.68v-8.06M26.2 46.62h14.33v-17.9a7.16 7.16 0 00-14.32 0v17.9zM26.2 34.98l14.33-6.26M26.2 42.14l14.33-6.26"/>
    </SvgIcon>
  );
}
