import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

export function Explore({ color='#000', ...props }) {
  return (
    <SvgIcon fill="none" viewBox="0 0 17 21" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.886 1.118a7.73 7.73 0 0 0 5.358 14.28l2.464 4.267a2.496 2.496 0 0 0 3.411.91 2.496 2.496 0 0 0 .919-3.41l-2.464-4.267a7.73 7.73 0 0 0-9.688-11.78Zm-1.53 9.809a6.23 6.23 0 1 1 10.79-6.23 6.23 6.23 0 0 1-10.79 6.23ZM12.45 13.95a7.8 7.8 0 0 1-1.732 1l2.289 3.964a.999.999 0 0 0 1.364.364.999.999 0 0 0 .368-1.364l-2.29-3.964Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.569 3.342a.75.75 0 0 1-.7.793h-.004l-.031.004a2.565 2.565 0 0 0-.58.136 2.197 2.197 0 0 0-.616.372 2.862 2.862 0 0 0-.241.224l-.009.01m0 0a.75.75 0 0 1-1.125-.992l.563.495-.563-.495.002-.002.003-.003.008-.01a2.362 2.362 0 0 1 .11-.114c.07-.07.17-.165.295-.268a3.692 3.692 0 0 1 1.058-.626 4.066 4.066 0 0 1 1.002-.225l.022-.002h.011l.046.748-.045-.749a.75.75 0 0 1 .794.704"
        fill={color}
      />
    </SvgIcon>
  );
}
Explore.propTypes = {
  color: PropTypes.string,
};
