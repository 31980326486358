import { SVGProps } from "react"

export const RatingUnselected4 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    {...props}
  >
    <path d="M18 36a18 18 0 1 0 0-36 18 18 0 0 0 0 36Z" fill="#E0E0E0" />
    <path
      d="M18 21c-3.62 0-6.03-.42-9-1-.68-.13-2 0-2 2 0 4 4.6 9 11 9s11-5 11-9c0-2-1.32-2.13-2-2-2.97.58-5.38 1-9 1Z"
      fill="#9E9E9E"
    />
    <path d="M9 22s3 1 9 1 9-1 9-1-2 4-9 4-9-4-9-4Z" fill="#fff" />
    <path
      d="M12 17c1.38 0 2.5-1.57 2.5-3.5S13.38 10 12 10s-2.5 1.57-2.5 3.5S10.62 17 12 17Zm12 0c1.38 0 2.5-1.57 2.5-3.5S25.38 10 24 10s-2.5 1.57-2.5 3.5S22.62 17 24 17Z"
      fill="#9E9E9E"
    />
  </svg>
)

export default RatingUnselected4
