import { FC } from 'react';


export type HomeSelectedProps = {
  color?: string,
};


export const HomeSelected: FC<HomeSelectedProps> = ({ color='rgba(0, 0, 0, 0.87)' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 22">
    <defs/>
    <path fill={color} fillRule="evenodd" d="M8.2.86a2.99 2.99 0 013.6 0L18.56 6c.74.58 1.19 1.47 1.19 2.43v10.33c0 .53 0 1-.04 1.36-.04.37-.14.8-.47 1.13a1.8 1.8 0 01-1.11.46c-.36.04-.81.04-1.34.04h-5.3a.75.75 0 01-.74-.83V18.5c0-.41.34-.75.75-.75H13c.14 0 .25-.11.25-.25v-6.86a2.1 2.1 0 00-.88-1.63A3.86 3.86 0 0010 8.25c-.91 0-1.76.29-2.37.76a2.1 2.1 0 00-.88 1.63v6.86c0 .14.11.25.25.25h1.5c.41 0 .75.34.75.75v2.42a.75.75 0 01-.74.83H3.2c-.53 0-.98 0-1.34-.04a1.8 1.8 0 01-1.11-.46 1.8 1.8 0 01-.47-1.13c-.04-.36-.04-.83-.04-1.36V8.43c0-.96.45-1.85 1.2-2.43L8.2.86z" clipRule="evenodd"/>
  </svg>
);
