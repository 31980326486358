import { FC } from "react"
import { CheckCircle } from "./CheckCircle"
import { CheckCircleHalf } from "./CheckCircleHalf"
import { CheckCircleFilled } from "./CheckCircleFilled"


export type WatchedProps = {
  isWatched: number,
  selectedColor?: string,
  unselectedColor?: string,
  sx?: object,
}


export const Watched: FC<WatchedProps> = function Watched({ isWatched, selectedColor=undefined, unselectedColor='undefined', sx={} }) {
  if(isWatched > 0 && isWatched < 1) {
    return (<CheckCircleHalf sx={{ color: selectedColor || 'primary.main', ...sx }} />);
  } else if(isWatched === 1) {
    return (<CheckCircleFilled sx={{ color: selectedColor || 'primary.main', ...sx }} />);
  }

  return (
    <CheckCircle sx={{ color: unselectedColor || 'text.primary', strokeWidth: 2, ...sx }} />
  );
}
