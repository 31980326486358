import * as React from "react"

export const RepeatInCircle = (props) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <circle
      cx={12}
      cy={12}
      r={11.25}
      stroke="#000"
      strokeOpacity={0.87}
      strokeWidth={1.5}
    />
    <path
      d="m15 5.4 2.4 2.4m0 0L15 10.2m2.4-2.4H9a2.4 2.4 0 0 0-2.4 2.4v1.2M9 18.6l-2.4-2.4m0 0L9 13.8m-2.4 2.4H15a2.4 2.4 0 0 0 2.4-2.4v-1.2"
      stroke="#000"
      strokeOpacity={0.87}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
