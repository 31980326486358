import { CSSProperties, FC } from 'react';


export type FriendsSelectedProps = {
  color?: string,
  style?: CSSProperties,
};


export const FriendsSelected: FC<FriendsSelectedProps> = ({ color='rgba(0, 0, 0, 0.87)', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 23"
    {...props}
  >
    <path
      fill={color}
      fillOpacity={0.87}
      d="M2.5 15H2a2 2 0 0 1-2-2V5.238C0 2.5 2.206 0 5 0c1.81 0 3.375 1.05 4.251 2.535C5.558 2.881 2.5 5.752 2.5 9.238V15Zm8.249-12.465c3.693.346 6.751 3.217 6.751 6.703V15h.5a2 2 0 0 0 2-2V5.238C20 2.5 17.794 0 15 0c-1.81 0-3.375 1.05-4.251 2.535Z"
    />
    <path
      fill={color}
      fillOpacity={0.87}
      fillRule="evenodd"
      d="M6 19h1.5v1.447a2.5 2.5 0 0 0 5 0V19H14a2 2 0 0 0 2-2V9.238C16 6.5 13.352 4 10 4S4 6.5 4 9.238V17a2 2 0 0 0 2 2Zm2.444-4.73a.5.5 0 0 0-.891.454L8 14.5l-.447.225.002.004.004.006.009.017.029.05a2.42 2.42 0 0 0 .528.588C8.52 15.706 9.13 16 10 16s1.48-.294 1.875-.61a2.416 2.416 0 0 0 .528-.588l.03-.05.008-.017.004-.006.001-.003v-.001l.001-.001-.445-.223.445.223a.5.5 0 1 0-.899-.44 1.421 1.421 0 0 1-.298.326c-.23.184-.621.39-1.25.39s-1.02-.206-1.25-.39a1.42 1.42 0 0 1-.306-.34Z"
      clipRule="evenodd"
    />
  </svg>
)
