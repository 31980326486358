import { FC } from 'react';


export type SavedSelectedProps = {
  color?: string,
};


export const SavedSelected: FC<SavedSelectedProps> = ({ color='rgba(0, 0, 0, 0.87)' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 22">
    <defs/>
    <path fill={color} fillRule="evenodd" d="M2.57 2.16A8.44 8.44 0 018 .25c2.02 0 3.98.68 5.43 1.91a6.38 6.38 0 012.26 3.92.75.75 0 01-.52.83L1.23 11.34a.75.75 0 01-.98-.71v-3.7c0-1.82.85-3.53 2.32-4.77zm12.87 7.6c.2.15.31.37.31.61V20c0 1.35-1.48 2.2-2.64 1.5l-4.98-2.95a.25.25 0 00-.26 0L2.9 21.5A1.75 1.75 0 01.25 20v-5.17c0-.33.21-.62.52-.72l14-4.45a.75.75 0 01.67.1z" clipRule="evenodd"/>
  </svg>
);
