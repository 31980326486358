
import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

export function Globe({ fill = '#fff', ...props }) {
  return (
    <SvgIcon {...props} viewBox="0 0 14 14">
      <path fill={fill} d="M7 0C3.14031 0 0 3.14031 0 7C0 10.8597 3.14031 14 7 14C10.8597 14 14 10.8597 14 7C14 3.14031 10.8597 0 7 0ZM7 1.07692C7.182 1.07692 7.36077 1.08769 7.53846 1.10385V2.15385H6.46154L5.92308 3.76923H5.38462V4.30769H8.07692L8.61539 4.84615H9.15385V5.38462L8.61539 5.92308L6.46154 5.38462L4.30769 7V8.61539L5.38462 9.69231H7V11.0385L7.53846 12.3846H8.07692L9.69231 10.2308V9.69231L10.2308 8.61539V8.07692H9.69231L9.15385 7L9.69231 6.46154L10.5 7L11.8462 6.46154V5.92308H12.8202C12.8848 6.27308 12.9231 6.63169 12.9231 7C12.9231 10.2658 10.2658 12.9231 7 12.9231C3.73423 12.9231 1.07692 10.2658 1.07692 7C1.07692 3.73423 3.73423 1.07692 7 1.07692Z"/>
    </SvgIcon>
  );
}

Globe.propTypes = {
  fill: PropTypes.string,
};
