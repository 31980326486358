import { SVGProps } from "react"

export const RatingSelected5 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    {...props}
  >
    <path d="M36 18a18 18 0 1 1-36 0 18 18 0 0 1 36 0Z" fill="#FFCC4D" />
    <path
      d="M18 21c-3.62 0-6.03-.42-9-1-.68-.13-2 0-2 2 0 4 4.6 9 11 9s11-5 11-9c0-2-1.32-2.13-2-2-2.97.58-5.38 1-9 1Z"
      fill="#664500"
    />
    <path d="M9 22s3 1 9 1 9-1 9-1-2 4-9 4-9-4-9-4Z" fill="#fff" />
    <path
      d="m15.68 4.41-4.54.8L8.8.96a1.25 1.25 0 0 0-2.33.41l-.75 4.8-4.54.8a1.25 1.25 0 0 0-.32 2.36l4.07 1.93-.74 4.81a1.25 1.25 0 0 0 2.12 1.07l3.51-3.56 4.52 2.15A1.25 1.25 0 0 0 15.97 14l-2.35-4.27 3.17-3.2a1.25 1.25 0 0 0-1.1-2.12Zm4.64 0 4.54.8L27.2.96a1.25 1.25 0 0 1 2.33.41l.74 4.8 4.55.8a1.25 1.25 0 0 1 .31 2.36l-4.07 1.93.75 4.81a1.25 1.25 0 0 1-2.12 1.07l-3.52-3.56-4.51 2.15A1.25 1.25 0 0 1 20.03 14l2.34-4.27-3.16-3.2a1.25 1.25 0 0 1 1.1-2.12Z"
      fill="#E95F28"
    />
  </svg>
)

export default RatingSelected5;
