import { SvgIcon } from '@mui/material';

export const Kawaii = (props) => {
  return (
    <SvgIcon viewBox="0 0 140 224" fill="none" {...props}>
      <path d="M50 184H82V212C82 218.627 76.6274 224 70 224H62C55.3726 224 50 218.627 50 212V184Z" fill="#EBB470"/>
      <path d="M58 184H90V212C90 218.627 84.6274 224 78 224H70C63.3726 224 58 218.627 58 212V184Z" fill="#F1CB9C"/>
      <path d="M0 40C0 17.9086 17.9086 0 40 0H88C110.091 0 128 17.9086 128 40V144C128 166.091 110.091 184 88 184H40C17.9086 184 0 166.091 0 144V40Z" fill="#FE2F77"/>
      <path d="M12 40C12 17.9086 29.9086 0 52 0H100C122.091 0 140 17.9086 140 40V144C140 166.091 122.091 184 100 184H52C29.9086 184 12 166.091 12 144V40Z" fill="#FE94B9"/>
      <circle cx="36" cy="122" r="12" fill="#50302D"/>
      <circle cx="40" cy="118" r="4" fill="white"/>
      <circle cx="116" cy="122" r="12" fill="#50302D"/>
      <circle cx="120" cy="118" r="4" fill="white"/>
      <path d="M75.4782 159.983C69.5027 159.821 66.1257 158.702 61.2174 155.657C58.3204 153.859 55.6999 150.693 53.5706 147.473C50.4392 142.739 54.2028 137 59.8794 137H92.9388C98.3882 137 102.158 142.339 99.4116 147.046C97.4442 150.417 94.9016 153.791 91.8976 155.657C86.5727 158.963 81.9627 160.159 75.4782 159.983Z" fill="#50302D"/>
      <path d="M66.4563 155.386C69.1213 156.431 71.8102 156.892 75.4782 156.991C79.2933 157.095 82.4595 156.723 85.5043 155.726C85.5601 155.308 85.589 154.881 85.589 154.447C85.589 149.154 81.2958 144.862 75.9999 144.862C70.704 144.862 66.4109 149.154 66.4109 154.447C66.4109 154.764 66.4263 155.077 66.4563 155.386Z" fill="#FE2F77"/>
    </SvgIcon>
  )
}

