import { SVGProps } from "react"

export const RatingUnselected1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    {...props}
  >
    <path d="M36 18a18 18 0 1 1-36 0 18 18 0 0 1 36 0Z" fill="#E0E0E0" />
    <path
      d="M25.48 29.88c-.04-.18-1.16-4.38-7.48-4.38s-7.44 4.2-7.48 4.38a.5.5 0 0 0 .23.55c.2.11.44.08.6-.07.02-.02 1.96-1.86 6.65-1.86 4.7 0 6.63 1.84 6.65 1.86a.5.5 0 0 0 .6.08.5.5 0 0 0 .23-.56Zm-9.77-12.59C12.45 14.04 7.22 14 7 14a1 1 0 1 0 0 2c.03 0 1.92.02 3.98.74-.6.64-.98 1.63-.98 2.76 0 1.93 1.12 3.5 2.5 3.5s2.5-1.57 2.5-3.5c0-.17-.02-.34-.04-.5H15a1 1 0 0 0 .7-1.7ZM29 14c-.22 0-5.45.04-8.7 3.3A1 1 0 0 0 21 19h.04c-.02.16-.04.33-.04.5 0 1.93 1.12 3.5 2.5 3.5s2.5-1.57 2.5-3.5c0-1.13-.39-2.12-.98-2.76 2.06-.72 3.95-.74 3.98-.74a1 1 0 0 0 0-2Z"
      fill="#9E9E9E"
    />
  </svg>
)

export default RatingUnselected1;
