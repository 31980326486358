import { SvgIcon } from '@mui/material';

export const ListIcon = (props) => (
  <SvgIcon {...props}>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 12">
      <defs/>
      <path fill="#fff" d="M10 3.5H0v1.67h10V3.5zm0-3.33H0v1.66h10V.17zM0 8.5h6.67V6.83H0V8.5zm16.25-3.75L17.5 6l-5.82 5.83-3.76-3.75 1.25-1.25 2.5 2.5 4.58-4.58z"/>
    </svg>
  </SvgIcon>
);
