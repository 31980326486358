import { createElement, FC, SVGProps } from 'react';
import { RatingSelected1 } from './rating/RatingSelected1';
import { RatingSelected2 } from './rating/RatingSelected2';
import { RatingSelected4 } from './rating/RatingSelected4';
import { RatingSelected5 } from './rating/RatingSelected5';
import { RatingUnselected1 } from './rating/RatingUnselected1';
import { RatingUnselected2 } from './rating/RatingUnselected2';
import { RatingUnselected4 } from './rating/RatingUnselected4';
import { RatingUnselected5 } from './rating/RatingUnselected5';
import { SlightlySmilingFace } from './rating/SlightlySmilingFace';
import { SlightlySmilingFaceUnselected } from './rating/SlightlySmilingFaceUnselected';


export interface RatingIconProps extends SVGProps<SVGSVGElement> {
  value: number;
  highlight: boolean;
}


export const RatingIcon: FC<RatingIconProps> = function RatingIcon({ value, highlight, ...rest }) {
  const selectedIcons = [
    null,
    RatingSelected1,
    RatingSelected2,
    SlightlySmilingFace,
    RatingSelected4,
    RatingSelected5,
  ] as FC<SVGProps<SVGSVGElement>>[];
  const unselectedIcons = [
    null,
    RatingUnselected1,
    RatingUnselected2,
    SlightlySmilingFaceUnselected,
    RatingUnselected4,
    RatingUnselected5,
  ] as FC<SVGProps<SVGSVGElement>>[];

  if(highlight) {
    return createElement(selectedIcons[value], { width: '1em', key: value, ...rest });
  } else {
    return createElement(unselectedIcons[value], { width: '1em', key: value, ...rest });
  }
}
