
export const MessageSquareInCircle = (props) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="M17.5 14.17a1.11 1.11 0 0 1-1.11 1.1H9.72L7.5 17.5V8.61A1.11 1.11 0 0 1 8.61 7.5h7.78a1.11 1.11 0 0 1 1.11 1.11v5.56Z"
      stroke="#000"
      strokeOpacity={0.87}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={0.75}
      y={0.75}
      width={22.5}
      height={22.5}
      rx={11.25}
      stroke="#000"
      strokeOpacity={0.87}
      strokeWidth={1.5}
    />
  </svg>
)
