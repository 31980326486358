import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

export const CheckCircle = ({ sx, ...props }) => (
  <SvgIcon fill="none" viewBox="0 0 20 20" {...props} sx={{ color: '#fff', fill: 'none', stroke: 'currentColor', strokeWidth: 1.5, ...sx }}>
    <path
      d="m6.4 10.45 2.7 2.7 4.5-5.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
    />
  </SvgIcon>
);

CheckCircle.propTypes = {
  sx: PropTypes.object
};
