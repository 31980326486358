import { FC, useId } from 'react';
import { SvgIcon, SxProps } from '@mui/material';


export type CheckCircleHalfProps = {
  sx?: SxProps,
};


export const CheckCircleHalf: FC<CheckCircleHalfProps> = ({ sx={}, ...props }) => {

  const idPrefix = useId();

  return (
    <SvgIcon fill="none" viewBox="0 0 18 18" {...props} sx={{ fill: 'currentColor', ...sx}}>
      <defs>
        <clipPath id={`${idPrefix}-a`}>
          <path d="m0 0 18 18V0z" />
        </clipPath>
        <clipPath id={`${idPrefix}-b`}>
          <path d="m0 0 20 20H0z" />
        </clipPath>
      </defs>
      <g fill="currentColor" clipPath={`url(#${idPrefix}-a)`} color="currentColor">
        <path
          fillRule="evenodd"
          d="M9 0a9 9 0 1 0 0 18A9 9 0 0 0 9 0zm3.9 7.48a.82.82 0 1 0-1.26-1.05l-3.51 4.22L6.3 8.83A.82.82 0 0 0 5.15 10l2.45 2.45a.82.82 0 0 0 1.21-.05l4.1-4.91z"
          clipRule="evenodd"
        />
      </g>
      <g
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        clipPath={`url(#${idPrefix}-b)`}
        transform="scale(.9)"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m6.4 10.45 2.7 2.7 4.5-5.4"
        />
        <path d="M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18z" />
      </g>
    </SvgIcon>
  );
}
