import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

export function FallbackImage({ color, scale = 1, style, ...props }) {

  const [ nr, setNr ] = useState(0);

  useEffect(() => {
    setNr(Math.floor(Math.random() * 2));
  }, []);

  return (
    <SvgIcon style={{ backgroundColor: color, ...style }} {...props} fill="none" viewBox="0 0 275 213">

      <g
        visibility="hidden"
        transform={`scale(${scale})`}
        style={{ transformOrigin: "50% 50%" }}
      >
        <path
          fill="none"
          visibility={nr === 0 ? 'visible' : 'hidden'}
          stroke="#fff"
          strokeWidth="4"
          d="M148.656 100.05c4.262 0 4.262-3.225 1.065-3.225C149.721 90.375 145.459 85 138 85c-7.459 0-11.721 5.375-11.721 11.825-3.197 0-3.197 3.225 1.065 3.225h21.312zm-21.312 0h21.312L138 128l-10.656-27.95z"
        ></path>
        <path
          fill="none"
          visibility={nr === 1 ? 'visible' : 'hidden'}
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M137.5 128.938V118.25M147 94.5v23.75h-19V94.5a9.502 9.502 0 019.5-9.5 9.501 9.501 0 019.5 9.5zm0 0l-19 8.312m0 9.5L147 104"
        ></path>
      </g>
    </SvgIcon>
  );
}

FallbackImage.propTypes = {
  color: PropTypes.string.isRequired,
  scale: PropTypes.number,
  style: PropTypes.any,
}
