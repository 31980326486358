import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

export const Check = ({ style, ...props }) => (
  <SvgIcon fill="none" viewBox="0 0 20 20" {...props} style={{ fill: 'none', stroke: '#fff', strokeWidth: 1.5, ...style }}>
    <path
      d="m6.4 10.45 2.7 2.7 4.5-5.4"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

Check.propTypes = {
  style: PropTypes.object,
};
