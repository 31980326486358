import { SvgIcon } from '@mui/material';

export function ErrorIceCream(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 208 187">
      <path
        fill="#C3362B"
        d="M107.5 118c-.928 0-1.818.356-2.475.989a3.314 3.314 0 00-1.025 2.386v13.5c0 .895-.369 1.754-1.025 2.386a3.569 3.569 0 01-2.475.989 3.567 3.567 0 01-2.475-.989A3.312 3.312 0 0197 134.875v-13.5c0-.895-.369-1.754-1.025-2.386A3.567 3.567 0 0093.5 118c-.928 0-1.819.356-2.475.989A3.312 3.312 0 0090 121.375v13.5c0 2.685 1.106 5.261 3.075 7.159 1.97 1.899 4.64 2.966 7.425 2.966s5.455-1.067 7.425-2.966c1.969-1.898 3.075-4.474 3.075-7.159v-13.5c0-.895-.369-1.754-1.025-2.386A3.569 3.569 0 00107.5 118zM105.014 47.992L79.078 73.927a3.532 3.532 0 104.995 4.995l25.936-25.936a3.533 3.533 0 00-4.995-4.994zM72.492 80.983l-6.5 7c-.31.322-.558.709-.728 1.136a3.731 3.731 0 00-.022 2.706c.163.43.405.821.71 1.15.305.329.668.588 1.067.764a3.048 3.048 0 002.514-.023c.396-.184.755-.45 1.055-.784l6.5-7c.592-.66.92-1.545.912-2.462a3.64 3.64 0 00-.952-2.445A3.139 3.139 0 0074.778 80a3.13 3.13 0 00-2.286.983zM55.251 97.979l-12.334 12.68C36.567 117.205 33 126.073 33 135.318c0 9.246 3.566 18.114 9.917 24.659A3.343 3.343 0 0045.316 161c.9 0 1.763-.367 2.4-1.021a3.538 3.538 0 00.994-2.466 3.54 3.54 0 00-.993-2.467c-5.081-5.236-7.935-12.331-7.935-19.728 0-7.396 2.854-14.491 7.935-19.728l12.33-12.68c.619-.658.96-1.539.953-2.453a3.537 3.537 0 00-.994-2.436 3.347 3.347 0 00-2.369-1.02 3.344 3.344 0 00-2.386.978z"
      ></path>
      <path
        fill="#C3362B"
        d="M193.118 1.014a3.467 3.467 0 00-4.902 0L135.2 53.974l-25.282-25.256a3.469 3.469 0 00-4.902 0l-72.135 72.054a48.522 48.522 0 00-14.147 32.17 48.515 48.515 0 0011.287 33.28H10.4a10.407 10.407 0 00-7.354 3.043 10.383 10.383 0 000 14.692A10.407 10.407 0 0010.4 187h124.8c2.759 0 5.404-1.095 7.354-3.043a10.381 10.381 0 000-14.692 10.405 10.405 0 00-7.354-3.043h-19.364l15.984-15.968a10.39 10.39 0 003.707 6.686 10.411 10.411 0 0014.199-.924 10.385 10.385 0 002.808-7.109v-19.344l6.933-6.926v19.344c0 2.756 1.096 5.398 3.046 7.346a10.405 10.405 0 0014.708 0 10.379 10.379 0 003.046-7.346v-41.555c0-.919-.365-1.8-1.015-2.449l-25.283-25.255 53.016-52.96a3.46 3.46 0 000-4.896L193.118 1.014zm-19.784 100.845v40.122a3.46 3.46 0 01-3.467 3.463 3.46 3.46 0 01-3.466-3.463v-27.704a3.467 3.467 0 00-5.918-2.448l-13.867 13.852a3.46 3.46 0 00-1.015 2.448v20.778a3.461 3.461 0 01-3.467 3.463 3.468 3.468 0 01-3.467-3.463v-6.926a3.455 3.455 0 00-2.14-3.198 3.467 3.467 0 00-3.777.75l-27.734 27.704a3.458 3.458 0 00.525 5.327c.57.381 1.241.584 1.926.584H135.2a3.464 3.464 0 110 6.926H10.4a3.462 3.462 0 110-6.926h27.733a3.465 3.465 0 002.451-5.911l-2.8-2.798a41.584 41.584 0 01-12.164-29.384 41.58 41.58 0 0112.163-29.383l69.684-69.61 65.867 65.797zm-24.267-34.034l-8.965-8.955 50.565-50.511 8.965 8.955-50.565 50.511z"
      ></path>
    </SvgIcon>
  );
}
