import { CSSProperties, FC } from 'react';


export type FriendsProps = {
  color?: string,
  style?: CSSProperties,
};


export const Friends: FC<FriendsProps> = ({ color='rgba(0, 0, 0, 0.87)', ...props }) => (

  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 23"
    {...props}
    // eslint-disable-next-line react/prop-types
    style={{ fill: color, ...props.style }}
  >
    <path
      d="M18.5 5.238V13a.5.5 0 0 1-.5.5h-.5V15h.5a2 2 0 0 0 2-2V5.238C20 2.5 17.794 0 15 0c-1.81 0-3.375 1.05-4.251 2.535a8.03 8.03 0 0 1 1.597.315C12.999 2.032 13.963 1.5 15 1.5c1.866 0 3.5 1.726 3.5 3.738ZM7.654 2.85C7.001 2.032 6.037 1.5 5 1.5c-1.866 0-3.5 1.726-3.5 3.738V13a.5.5 0 0 0 .5.5h.5V15H2a2 2 0 0 1-2-2V5.238C0 2.5 2.206 0 5 0c1.81 0 3.375 1.05 4.251 2.535a8.03 8.03 0 0 0-1.597.315Zm.122 11.203a.5.5 0 0 1 .676.23 1.42 1.42 0 0 0 .298.326c.23.185.621.391 1.25.391s1.02-.206 1.25-.39a1.421 1.421 0 0 0 .298-.326l.008-.013a.5.5 0 0 1 .891.453L12 14.5l.447.224v.002l-.002.003-.004.006-.009.017a1.485 1.485 0 0 1-.134.204c-.091.122-.229.28-.423.434-.395.316-1.004.61-1.875.61s-1.48-.294-1.875-.61a2.42 2.42 0 0 1-.528-.588 1.416 1.416 0 0 1-.03-.05l-.008-.017-.004-.006-.001-.003v-.001s-.001-.001.446-.225l-.447.224a.5.5 0 0 1 .223-.671Z"
    />
    <path
      fillRule="evenodd"
      d="M6 19h1.5v1.447a2.5 2.5 0 0 0 5 0V19H14a2 2 0 0 0 2-2V9.238C16 6.5 13.352 4 10 4S4 6.5 4 9.238V17a2 2 0 0 0 2 2Zm3 0v1.447a1 1 0 0 0 2 0V19H9Zm-3.5-2a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V9.238C14.5 7.481 12.685 5.5 10 5.5S5.5 7.48 5.5 9.238V17Z"
      clipRule="evenodd"
    />
  </svg>
)
