import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

export const Clubs = ({ fill='#fff', stroke="rgba(0, 0, 0, 0.87)", ...props }) => {

  return (
    <SvgIcon viewBox="0 0 21 19" fill="none" {...props}>
      <circle
        cx={6.5}
        cy={12.5}
        r={5.5}
        fill={fill}
        stroke={stroke}
        strokeWidth={1.5}
      />
      <circle
        cx={10.5}
        cy={6.5}
        r={5.5}
        fill={fill}
        stroke={stroke}
        strokeWidth={1.5}
      />
      <circle
        cx={14.5}
        cy={12.5}
        r={5.5}
        fill={fill}
        stroke={stroke}
        strokeWidth={1.5}
      />
      <path
        d="M19.954 12.115H18.13c-1.43 0-.132 2.2-1.43 2.2s0-2.2-1.43-2.2H9.046"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

Clubs.propTypes = {
  fill: PropTypes.string,
  stroke: PropTypes.string,
};
