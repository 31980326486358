import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

export function Share({ backgroundFill, foregroundFill, arrowStroke, ...rest }) {
  return (
    <SvgIcon { ...rest } viewBox="0 0 45 40">
      <path fill={backgroundFill} d="M0.5 20C0.5 31.0457 9.4543 40 20.5 40H24.5C35.5457 40 44.5 31.0457 44.5 20C44.5 8.9543 35.5457 0 24.5 0H20.5C9.4543 0 0.5 8.9543 0.5 20Z"/>
      <path fill={foregroundFill} d="M44.5 20C44.5 31.0457 35.5457 40 24.5 40C13.4543 40 4.5 31.0457 4.5 20C4.5 8.9543 13.4543 0 24.5 0C35.5457 0 44.5 8.9543 44.5 20Z"/>
      <path stroke={arrowStroke} d="M15 20.5H34M34 20.5L24.5 11M34 20.5L24.5 30" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
  );
}

Share.propTypes = {
  arrowStroke: PropTypes.string.isRequired,
  foregroundFill: PropTypes.string.isRequired,
  backgroundFill: PropTypes.string.isRequired,
};
