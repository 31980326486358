
import { SvgIcon } from '@mui/material';

export function ArrowRight(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 14">
      <path d="M10.5026 0.341494C10.1389 -0.0741428 9.50713 -0.11626 9.0915 0.247422C8.67586 0.611104 8.63374 1.24287 8.99742 1.6585L12.7962 6H1C0.447715 6 0 6.44771 0 7C0 7.55228 0.447715 8 1 8H12.7962L8.99742 12.3415C8.63374 12.7571 8.67586 13.3889 9.0915 13.7526C9.50713 14.1163 10.1389 14.0741 10.5026 13.6585L15.7494 7.66214C15.9053 7.48577 16 7.25394 16 7C16 6.74605 15.9053 6.51421 15.7494 6.33784L10.5026 0.341494Z"/>
    </SvgIcon>
  );
}
