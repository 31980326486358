import { useId } from 'react';
import { SvgIcon } from '@mui/material';

export function LoadingIceCream(props) {
  const uuid = useId();

  return (
    <SvgIcon viewBox="260 495 140 224" {...props} >
      <path
        d="M310 679h32v28c0 6.627-5.373 12-12 12h-8c-6.627 0-12-5.373-12-12v-28z"
        fill="#EBB470"
      />
      <path
        d="M318 679h32v28c0 6.627-5.373 12-12 12h-8c-6.627 0-12-5.373-12-12v-28z"
        fill="#F1CB9C"
      />
      <path
        d="M260 535c0-22.091 17.909-40 40-40h48c22.091 0 40 17.909 40 40v104c0 22.091-17.909 40-40 40h-48c-22.091 0-40-17.909-40-40V535z"
        fill="#FE2F77"
      />
      <path
        d="M272 535c0-22.091 17.909-40 40-40h48c22.091 0 40 17.909 40 40v104c0 22.091-17.909 40-40 40h-48c-22.091 0-40-17.909-40-40V535z"
        fill="#FE94B9"
      />
      <mask
        id={uuid}
        maskUnits="userSpaceOnUse"
        x={272}
        y={495}
        width={128}
        height={184}
      >
        <path
          d="M272 535c0-22.091 17.909-40 40-40h48c22.091 0 40 17.909 40 40v104c0 22.091-17.909 40-40 40h-48c-22.091 0-40-17.909-40-40V535z"
          fill="#FE94B9"
        />
      </mask>
      <g mask={`url(#${uuid})`}>
        <g>
          <path
            fill="#F3DD6D"
            d="M240 662.692l427.817-247 40 69.282-427.817 247z"
          />
          <path
            fill="#7BC0E5"
            d="M200 593.41l427.817-247 40 69.282-427.817 247z"
          />
          <path
            fill="#FE94B9"
            d="M160 524.128l427.817-247 40 69.282L200 593.41z"
          />
          <path
            fill="#F3DD6D"
            d="M120 454.846l427.817-247 40 69.282-427.817 247z"
          />
          <path
            fill="#7BC0E5"
            d="M80 385.564l427.817-247 40 69.282-427.817 247z"
          />
          <path
            fill="#FE94B9"
            d="M40 316.282l427.817-247 40 69.282L80 385.564z"
          />
          <path fill="#F3DD6D" d="M0 247L427.817 0l40 69.282L40 316.282z" />
          <animateTransform
            attributeType="XML"
            attributeName="transform"
            type="translate"
            from="0 0"
            to="0 277"
            repeatCount="indefinite"
            dur="1.5s"
          />
        </g>
      </g>
      <path
        d="M296 629c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12z"
        fill="#50302D"
      />
      <path d="M300 617a4 4 0 100-8 4 4 0 000 8z" fill="#fff" />
      <path
        d="M376 629c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12z"
        fill="#50302D"
      />
      <path d="M380 617a4 4 0 100-8 4 4 0 000 8z" fill="#fff" />
      <path
        d="M335.478 654.983c-5.975-.162-9.352-1.281-14.261-4.326-2.897-1.798-5.517-4.964-7.646-8.184-3.132-4.734.632-10.473 6.308-10.473h33.06c5.449 0 9.219 5.339 6.473 10.046-1.968 3.371-4.51 6.745-7.514 8.611-5.325 3.306-9.935 4.502-16.42 4.326z"
        fill="#50302D"
      />
      <path
        d="M326.456 650.386c2.665 1.045 5.354 1.506 9.022 1.605 3.815.104 6.982-.268 10.026-1.265a9.63 9.63 0 00.085-1.279c0-5.293-4.293-9.585-9.589-9.585s-9.589 4.292-9.589 9.585c0 .317.015.63.045.939z"
        fill="#FE2F77"
      />
    </SvgIcon>
  )
}
