import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';

export const AddCircle = ({ sx, ...props }) => (
  <SvgIcon fill="none" viewBox="0 0 20 20" {...props} sx={{ color: '#fff', fill: 'none', stroke: 'currentColor', strokeWidth: 1.5, ...sx }}>
    <path
      d="M10 19a9 9 0 1 0 0-18 9 9 0 0 0 0 18ZM10 6.4v7.2M6.4 10h7.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

AddCircle.propTypes = {
  sx: PropTypes.object,
};
