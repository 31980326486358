import { SvgIcon } from '@mui/material';
import PropTypes from 'prop-types';

export function FallbackCanonical({ sprinkleColor, ...props }) {
  sprinkleColor = sprinkleColor || '#fff';
  return (
    <SvgIcon viewBox="0 0 750 584" {...props}>
      <g clipPath="url(#a)">
        <path fill="#000" d="M0 0h750v584H0z" />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.49994 .86613 -.86592 -.50006 110.89 493.325)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.90022 .43556 -.43532 -.9002 206.309 493.1)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.90022 .43556 -.43532 -.9002 119.728 317.193)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.65852 -.75264 .7527 -.65828 251.437 517.704)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(.26295 .96487 -.96481 .26272 161.66 361)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(.30009 .95397 -.95392 .29985 521.596 488.942)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.5379 .84308 -.84287 -.538 606.433 449.872)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.47813 -.87835 .87835 -.47789 610.417 101.042)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(.72028 -.69377 .69354 .72034 588 147.296)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.99713 .07656 -.07634 -.99702 618.665 420.02)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.86785 -.49695 .49707 -.86764 672.737 498.64)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.5379 .84308 -.84287 -.538 440.44 345.536)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.5379 .84308 -.84287 -.538 708.44 295.536)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.53159 -.84707 .84709 -.53134 715.017 351.154)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(.73005 .68348 -.68356 .72981 499.12 360.833)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.94564 .32541 -.32517 -.9456 230.005 280.224)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.96509 -.26216 .26233 -.96492 302.147 342.677)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.96509 -.26216 .26233 -.96492 132.739 641.13)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.96509 -.26216 .26233 -.96492 352.265 153.13)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.00292 -1.00006 .99994 -.0027 320.051 390.858)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.00292 -1.00006 .99994 -.0027 490.039 205.43)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(.47153 .88192 -.88192 .47128 153.816 29)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.7013 .71296 -.71272 -.70136 83.581 148.164)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.87329 -.48733 .48745 -.87308 134.356 156.157)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.683}
          rx={6.6}
          transform="matrix(-.18899 .98204 -.98183 -.18946 267.639 101.004)"
          fill={sprinkleColor}
        />
        <rect
          width={13.202}
          height={84.673}
          rx={6.601}
          transform="matrix(-.8545 .51957 -.51977 -.85424 528.291 138.331)"
          fill={sprinkleColor}
        />
        <rect
          width={13.204}
          height={84.661}
          rx={6.602}
          transform="matrix(.35443 .93515 -.93531 .35366 570.815 249.642)"
          fill={sprinkleColor}
        />
        <rect
          width={13.204}
          height={84.661}
          rx={6.602}
          transform="matrix(.72749 .6862 -.6867 .72686 276.198 368.845)"
          fill={sprinkleColor}
        />
        <rect
          width={13.207}
          height={84.644}
          rx={6.603}
          transform="matrix(-.92299 .38499 -.38507 -.92283 314.783 55.111)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.687}
          rx={6.6}
          transform="matrix(.82036 -.57195 .5717 .82039 408.074 181.525)"
          fill={sprinkleColor}
        />
        <rect
          width={13.202}
          height={84.673}
          rx={6.601}
          transform="matrix(-.5673 .82359 -.82366 -.56698 424.783 503.152)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.687}
          rx={6.6}
          transform="matrix(.0321 -.99954 .99942 .0323 303.723 546.259)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.43482 .90058 -.90038 -.43496 355.154 213.703)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.69482 .71927 -.71903 -.69489 171.988 246.011)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.43482 .90058 -.90038 -.43496 40.947 228.815)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.96509 -.26216 .26233 -.96492 578.739 42.13)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.00292 -1.00006 .99994 -.0027 708.039 151.43)"
          fill={sprinkleColor}
        />
        <rect
          width={13.2}
          height={84.639}
          rx={6.6}
          transform="matrix(-.47813 -.87835 .87835 -.47789 554.311 616.042)"
          fill={sprinkleColor}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={sprinkleColor} d="M0 0h750v584H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

FallbackCanonical.propTypes = {
  sprinkleColor: PropTypes.string
}
